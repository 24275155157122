import styled from 'styled-components';
import { ItemDesProps } from 'ListItemDes';
import React from 'react';

export default function ListItemDes({
  name,
  desc,
  ariaLabel,
  clickFn,
  img,
  alt,
}: ItemDesProps) {
  return (
    <ListItem onClick={() => clickFn()}>
      <ListItemIcon src={img} alt={alt} />
      <ListItemText>
        <ListItemTitle aria-label={ariaLabel}>{name}</ListItemTitle>
        <ListItemDescription>{desc}</ListItemDescription>
      </ListItemText>
    </ListItem>
  );
}

const ListItem = styled.li`
  background: #2c2f33;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #3a3d41;
  }
`;

const ListItemIcon = styled.img`
  width: 50px;
  height: 50px;
  background-color: #ddd;
  border-radius: 8px;
  margin-right: 15px;
`;

const ListItemText = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ListItemTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ListItemDescription = styled.p`
  font-size: 14px;
  margin: 5px 0 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
`;
