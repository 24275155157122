import React from 'react';
import styled from 'styled-components';
import { SideItemProps } from 'ListItem';

export default function SideListItem({
  ariaLabel,
  name,
  isSelected,
  clickFn,
}: SideItemProps) {
  return (
    <Item onClick={clickFn} isSelected={isSelected}>
      <ListItemText>
        <ListItemTitle aria-label={ariaLabel}>{name}</ListItemTitle>
      </ListItemText>
    </Item>
  );
}

const Item = styled.li<{ isSelected?: boolean }>`
  background: ${({ isSelected }) => (isSelected ? 'white' : '#2c2f33')};
  color: ${({ isSelected }) => (isSelected ? 'black' : 'inherit')};
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${({ isSelected }) => (isSelected ? 'white' : '#3a3d41')};
  }
`;

const ListItemText = styled.div`
  flex: 1;
`;

const ListItemTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
