import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../components/SideBar';
import TopNav from '../components/TopNav';
import { useLocation } from 'react-router-dom';
import { navState } from 'navigateState';
import { list as ListType } from 'chatList';
import ChatInterface from '../components/ChatInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ChatList from '../components/ChatList';

// 채팅내역 더미데이터
const businessHistory: ListType[] = [
  {
    name: '근무 시간 및 휴식 시간',
    ariaLabel: '근무 시간 및 휴식 시간에 대한 규정',
  },
];

export default function Chat() {
  const location = useLocation();
  const state = location.state as navState | undefined;

  useEffect(() => {
    if (state && state.key) {
      localStorage.setItem('key', state.key);
      setChatType(state.key);
    }
  }, [state]);

  const [chat, setChat] = useState<string>('새 채팅');
  const [data, setData] = useState<ListType[]>([]);
  const [chatType, setChatType] = useState<string>(() => {
    return localStorage.getItem('key') || '';
  });

  // 화면 크기에 따라 채팅 내역 초기 상태 설정
  const [isChatListOpen, setIsChatListOpen] = useState<boolean>(() => {
    return window.innerWidth >= 769;
  });

  // 화면 크기 변경 감지하여 상태 업데이트
  useEffect(() => {
    const handleResize = () => {
      setIsChatListOpen(window.innerWidth >= 769);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleChatList = () => {
    if (!isLoading) {
      setIsChatListOpen(!isChatListOpen);
    }
  };

  useEffect(() => {
    switch (chatType) {
      case 'business':
        setData(businessHistory);
        break;
      default:
        setData([]);
        break;
    }
  }, [chatType]);

  return (
    <>
      <TopNav isLoading={isLoading} />
      <Container>
        <Sidebar chatType={chatType} isLoading={isLoading} />
        <HamburgerMenu
          isLoading={isLoading}
          onClick={/*toggleChatList*/ () => window.alert('현재 준비중입니다.')}
        >
          <HamburgerIcon icon={faBars} />
        </HamburgerMenu>
        {/* {isChatListOpen && (
                    <ChatListContainer>
                        <ChatList
                            isChatListOpen={isChatListOpen}
                            data={data}
                            setChat={setChat}
                        />
                    </ChatListContainer>
                )} */}
        <Label>{voiceOver(state?.key)}</Label>
        <ChatInterface
          isChatListOpen={isChatListOpen}
          chat={chat}
          chatType={chatType}
          setIsLoading={setIsLoading}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #1c1e24;
  color: white;

  & > * {
    max-width: 100%;
  }
`;

const HamburgerMenu = styled.div<{ isLoading: boolean }>`
  display: none;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  pointer-events: ${({ isLoading }) => (isLoading ? 'none' : 'auto')};

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    left: 20px;
    top: 33px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const HamburgerIcon = styled(FontAwesomeIcon)`
  width: 30px;
  height: 30px;
  color: white;
`;

const ChatListContainer = styled.div`
  @media (max-width: 768px) {
    position: absolute;
    top: 9vh;
    left: 0;
    width: 100%;
    background-color: #23262a;
    z-index: 10;
    height: 91vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: 769px) {
    width: 230px;
    background-color: #23262a;
    height: 100%;
    display: block;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Label = styled.h3`
  padding: 0;
  clip: rect(0, 0, 0, 0);
  border: 0;
  background-color: aliceblue;
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
`;

function voiceOver(state: string | undefined): string {
  let voiceOverText = '';
  switch (state) {
    case 'business':
      voiceOverText = '하티 통합 근무 규정봇';
      break;
    case 'mail':
      voiceOverText = '메일 작성봇';
      break;
    case 'foreign':
      voiceOverText = '하티 외국어 근무 규정봇';
      break;
    case 'korea-lan':
      voiceOverText = '한국어 근무 규정봇';
      break;
    case 'korea':
      voiceOverText = '한국어 교육 어시스턴트';
      break;
  }
  return voiceOverText;
}
