import styled from 'styled-components';
import TopNav from '../components/TopNav';
import Sidebar from '../components/SideBar';
import ListItemDes from '../components/ListItemDes';
import img from '../assets/file.png';
import { useNavigate } from 'react-router-dom';
import { navState } from 'navigateState';
import { uuid } from '../utils/uuid';

const chatbots = [
  {
    name: '하티 통합 근무 규정봇',
    desc: '하티 통합 근무 규정에 대한 내용을 알려드립니다.',
    ariaLabel: '하티 통합 근무 규정봇 사용',
    key: 'business',
    img: img,
    alt: '하티 통합 근무 규정봇 이미지',
  },
  {
    name: '하티 한국어 근무 규정봇',
    desc: '하티 한국어 근무 규정에 대한 내용을 알려드립니다.',
    ariaLabel: '하티 한국어 근무 규정봇 사용',
    key: 'korea-lan',
    img: img,
    alt: '하티 한국어 근무 규정봇 이미지',
  },
  {
    name: '하티 외국어 근무 규정봇',
    desc: '하티 외국어 근무 규정에 대한 내용을 알려드립니다.',
    ariaLabel: '하티 외국어 근무 규정봇 사용',
    key: 'foreign',
    img: img,
    alt: '하티 외국어 근무 규정봇 이미지',
  },
  {
    name: '한국어 교육 어시스턴트',
    desc: '한국어에 대해 모르는 내용을 알려 드리겠습니다.',
    ariaLabel: '한국어 교육 어시스턴트 사용',
    key: 'korea',
    img: img,
    alt: '한국어 교육 어시스턴트 이미지',
  },
  {
    name: '이메일 작성봇',
    desc: '이메일 작성을 도와드립니다.',
    ariaLabel: '이메일 작성봇 사용',
    key: 'mail',
    img: img,
    alt: '이메일 작성봇 이미지',
  },
];

function Main() {
  localStorage.removeItem('key');
  localStorage.removeItem('chat');
  const navigate = useNavigate();

  let state: navState = { key: '' };

  uuid();

  return (
    <>
      <TopNav />
      <Container>
        <Sidebar />
        <MainContent>
          <Header>하티웍스 챗봇</Header>
          <p>사용하실 챗봇을 고르세요</p>
          <List>
            {chatbots.map((bot, index) => (
              <ListItemDes
                key={index}
                name={bot.name}
                desc={bot.desc}
                ariaLabel={bot.ariaLabel}
                clickFn={() =>
                  navigate('/chat', {
                    state: { key: bot.key },
                  })
                }
                img={bot.img}
                alt={bot.alt}
              />
            ))}
          </List>
        </MainContent>
      </Container>
    </>
  );
}

export default Main;

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #1c1e24;
  color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
  }
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px;
    text-align: center;
  }
`;

const Header = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 50px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 400px;
    overflow: hidden;
    overflow-y: scroll;
  }
`;
